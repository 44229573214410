import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useEffect,
  useReducer,
  useRef,
} from 'react';
export const useReducerWithMiddleware = <R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>,
  middlewareFns: ((
    state: ReducerState<R>,
    action: ReducerAction<R>
  ) => void)[],
  afterwareFns: ((
    state: ReducerState<R>,
    action: ReducerAction<R>
  ) => void)[]
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const aRef = useRef<ReducerAction<R> | null>();

  const dispatchWithMiddleware = (action: ReducerAction<R>) => {
    middlewareFns.forEach(
      (
        middlewareFn: (
          s: ReducerState<R>,
          a: ReducerAction<R>
        ) => void
      ) => middlewareFn(state, action)
    );

    aRef.current = action;

    dispatch(action);
  };

  useEffect(() => {
    if (!aRef.current) return;

    afterwareFns.forEach((afterwareFn) =>
      afterwareFn(state, aRef.current!)
    );

    aRef.current = null;
  }, [afterwareFns, state]);

  return [state, dispatchWithMiddleware];
};
