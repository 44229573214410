import './Start.css';
import { StartScreen } from './StartScreenDetails';

type StartProps = {
  modalScreen: StartScreen;
  onButtonClicked: () => void;
};

export const Start = ({
  onButtonClicked,
  modalScreen,
}: StartProps) => {
  const { title, desc, isLoading, buttonText } = modalScreen;
  return (
    <div className="modal">
      <div className="modal-content">
        <p> {title}</p>
        <p> {desc}</p>
        <p> {isLoading ? 'loading' : ''}</p>
        <button onClick={onButtonClicked}>{buttonText}</button>
      </div>
    </div>
  );
};
