import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
// import { LandingPage } from './app/landing/LandingPage';
import { Error } from './app/error/Error';
import { Arena } from './app/arena/Arena';
import { LandingPage } from './app/landing/LandingPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Error />,
    errorElement: <Error />,
  },
  {
    path: 'twordle',
    element: <LandingPage />,
  },
  {
    path: 'twordle/play',
    element: <Arena />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
