import { ModalType } from './start/StartScreenDetails';

export type State = {
  playingState: PlayingState;
  myBoard: string;
  oppBoard: string;
  myColor: string;
  oppColor: string;
  userId: string;
  myWord?: string;
  userName: string;
  endTime: number;
  lastUpdated: number;
  yourWord?: string; // can maybe combine this into a types
  modalType?: ModalType;
  timerDetails: TimerDetails;
  hasPlayed: boolean;
};

export type TimerDetails = {
  minutes: string;
  seconds: string;
  isTicking: boolean;
};

export enum PlayingState {
  END,
  PLAYING,
}
