import { v4 as uuidv4 } from 'uuid';
export const PING_START = 30; //seconds
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'waiting_started':
      return {
        ...state,
        buttonStatus: 'waiting',
        pingInterval: PING_START,
      };
    case 'waiting_canceled':
      return { ...state, buttonStatus: 'start' };
    case 'connection_closed':
      return { ...state, buttonStatus: 'start' };
    case 'waiting_matched':
      return { ...state, matched: true };
    case 'waiting_error': {
      console.log(`${action.error}`);
      return { ...state, buttonStatus: 'start' };
    }
    case 'connection_error': {
      console.log(`error ${action.error}`);
      return { ...state, buttonStatus: 'dulled' };
    }
    case 'ping_tick': {
      if (state.buttonStatus !== 'waiting') return state;
      if (state.pingInterval === 0) {
        return { ...state, pingInterval: PING_START };
      } else {
        return { ...state, pingInterval: state.pingInterval - 1 };
      }
    }
    case 'received_unknown_event': {
      console.log(
        `received unknown event: ${JSON.stringify(action.event)}`
      );
      return state;
    }
    default:
      return state;
  }
};

export const reducerMiddleware = (state: State, action: Action) => {
  switch (action.type) {
    case 'waiting_matched': {
      action.setArenaIdInLocalStorage(action.arenaId);
      action.navigateToArena();
      break;
    }

    case 'ping_tick': {
      if (state.pingInterval === 0) {
        action.pingServer();
      }
      break;
    }

    case 'waiting_started': {
      action.sendWaitingRoomEnter();
      break;
    }

    case 'waiting_canceled': {
      action.sendWaitingRoomLeave();
      break;
    }

    case 'connection_closed': {
      action.sendWaitingRoomLeave();
      break;
    }

    case 'on_app_start': {
      const userId = action.getUserIdInLocalStorage();
      if (userId) return;
      action.setUserIdInLocalStorage(uuidv4());
      break;
    }

    case 'username_change': {
      action.setUsernameSessionStorage(action.username);
      break;
    }
  }
};

export const reducerAfterware = (state: State, action: Action) => {
  switch (action.type) {
    case 'waiting_canceled': {
      break;
    }
  }
};

export type State = {
  buttonStatus: 'waiting' | 'start' | 'dulled';
  matched: boolean;
  pingInterval: number;
};

export type Action =
  | { type: 'waiting_started'; sendWaitingRoomEnter: () => void }
  | { type: 'waiting_canceled'; sendWaitingRoomLeave: () => void }
  | {
      type: 'waiting_matched';
      navigateToArena: () => void;
      setArenaIdInLocalStorage: (arenaId: string) => void;
      arenaId: string;
    } //todo i think you can remove this when we render pages by themselves
  | { type: 'waiting_error'; error: string }
  | {
      type: 'on_app_start';
      getUserIdInLocalStorage: () => string | null;
      setUserIdInLocalStorage: (userId: string) => void;
    }
  | { type: 'connection_closed'; sendWaitingRoomLeave: () => void }
  | { type: 'connection_error'; error: string }
  | { type: 'ping_tick'; pingServer: () => void }
  | { type: 'received_unknown_event'; event: any }
  | {
      type: 'username_change';
      setUsernameSessionStorage: (username: string) => void;
      username: string;
    };

export const wsEventToAction = (
  event: any,
  navigateToArena: () => void,
  setArenaIdInLocalStorage: (arenaId: string) => void
): Action => {
  console.log(`event received: ${JSON.stringify(event)}`);
  if (event?.twordle?.status === 'Enter') {
    const arenaId = event?.twordle?.arenaId!;
    return {
      type: 'waiting_matched',
      navigateToArena,
      setArenaIdInLocalStorage,
      arenaId,
    };
  }
  return { type: 'received_unknown_event', event };
};

export const eventToTwoModel = () => {};
