import './Error.css';
import logo from '../../logo.svg';
export const Error = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          "I will not say do not weep
          <br />
          for not all tears are an evil."
          <br />
          <br /> Martin Luther King Jr.
        </p>
      </header>
    </div>
  );
};
