export const calculateTimeRemaining = (
  endTime: number
): { minutes: string; seconds: string } => {
  const timeRemainingMs = endTime - Date.now();

  let minutes = `0`;
  let seconds = `00`;

  if (timeRemainingMs > 0) {
    minutes = Math.trunc(timeRemainingMs / 1000 / 60).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 1,
        useGrouping: false,
      }
    );
    seconds = Math.trunc(
      (timeRemainingMs / 1000) % 60
    ).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  return { minutes, seconds };
};
