export type StartScreen = {
  title: string;
  desc: string;
  isLoading: boolean;
  buttonText: string;
};

export enum ModalType {
  START,
  WAITING,
  WIN_REMATCH,
  LOSE_REMATCH,
  DRAW_REMATCH,
  REMATCH_DECLINED,
  ERROR,
}

//
export const firstStartScreen: StartScreen = {
  title: 'Are you ready?',
  isLoading: false,
  desc: 'Your opponent is waiting on you to start',
  buttonText: 'Start',
};

//
export const waitingStartScreen: StartScreen = {
  title: 'Waiting for opponent to start.',
  isLoading: true,
  desc: "We're letting them know right now.",
  buttonText: 'Cancel',
};

export const winRematchStartScreen: StartScreen = {
  title: 'You won!',
  isLoading: false,
  desc: 'Your opponent is waiting on you to rematch',
  buttonText: 'Rematch',
};

export const loseRematchStartScreen: StartScreen = {
  title: 'You lose!',
  isLoading: false,
  desc: 'Your opponent is waiting on you to rematch',
  buttonText: 'Rematch',
};

export const drawRematchStartScreen: StartScreen = {
  title: "Time's up!  It's a draw.",
  isLoading: false,
  desc: 'Your opponent is waiting on you to rematch',
  buttonText: 'Rematch',
};

export const rematchDeclinedStartScreen: StartScreen = {
  title: 'The opponent has declined a rematch.',
  isLoading: false,
  desc: "Disappointing, but that's the price of being too damn good.",
  buttonText: 'Exit',
};

export const errorStartScreen: StartScreen = {
  title: 'The opponent has disconnected',
  isLoading: false,
  desc: 'Sorry about that',
  buttonText: 'Exit',
};

// how to make immutable / static?
export const startScreenDetailsMap = new Map<ModalType, StartScreen>([
  [ModalType.START, firstStartScreen],
  [ModalType.WAITING, waitingStartScreen],
  [ModalType.WIN_REMATCH, winRematchStartScreen],
  [ModalType.DRAW_REMATCH, drawRematchStartScreen],
  [ModalType.LOSE_REMATCH, loseRematchStartScreen],
  [ModalType.REMATCH_DECLINED, rematchDeclinedStartScreen],
  [ModalType.ERROR, errorStartScreen],
]);
startScreenDetailsMap.set = (
  key: ModalType,
  value: StartScreen
): Map<ModalType, StartScreen> => {
  throw new Error('stephen chyau made this immutable');
};
startScreenDetailsMap.clear = (): void => {
  throw new Error('stephen chyau made this immutable');
};
startScreenDetailsMap.delete = (key: ModalType): boolean => {
  throw new Error('stephen chyau made this immutable');
};
