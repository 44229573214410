import './Board.css';

export type BoardProps = {
  text: string;
  color: string;
  opponentGuessWord?: string; // the word our opponent guesses
};

export const Board = ({
  text,
  color,
  opponentGuessWord,
}: BoardProps) => {
  if (opponentGuessWord && opponentGuessWord.length !== 5)
    throw Error(
      `a dumb opponnentGuessWord was passed in ${opponentGuessWord}`
    );
  const lastRowStartIndex = Math.floor(text.length / 5) * 5;

  const renderArray = Array(30)
    .fill(undefined)
    .map((_, ind) => {
      let letter = '';
      if (text && ind < text.length) {
        letter = text.charAt(ind);
      }

      // get color
      let colorState = '';
      let colorStateClassName = '';
      if (color && ind < color.length) {
        colorState = color.charAt(ind);
      }
      switch (colorState) {
        case 'c':
          colorStateClassName = 'correct';
          break;
        case 'p':
          colorStateClassName = 'present';
          break;
        case 'a':
          colorStateClassName = 'absent';
          break;
        case '': // default
          break;
        default:
          throw Error(`unidentified char at ${ind} ${text}`);
      }

      // show hint for last word if nothing is there.
      // 28 29 30 31 32 33 34
      // 34 is an enter I believe.
      const isLastRowUnguessed = text.length <= 25;
      const isLastRowRendering = ind >= 25 && ind < 30;
      if (
        opponentGuessWord &&
        isLastRowUnguessed &&
        isLastRowRendering
      ) {
        colorStateClassName = 'word';
        letter = opponentGuessWord[ind - 25];
      }

      // generate the array
      return (
        <div className={`board-item ${colorStateClassName}`}>
          {letter.toUpperCase()}
        </div>
      );
    });

  return (
    <div>
      <div className="board">{renderArray}</div>
    </div>
  );
};
