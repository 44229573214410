/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import useWebSocket from 'react-use-websocket';
import './LandingPage.css';
import {
  PING_START,
  reducer,
  reducerAfterware,
  reducerMiddleware,
  wsEventToAction,
} from './reducer';
import {
  PING,
  WAITING_ROOM_ENTER_ACTION,
  WAITING_ROOM_LEAVE_ACTION,
} from '../shared/SocketEvents';
import { socketAddress } from '../shared/MySocket';
import { useNavigate, useBeforeUnload } from 'react-router-dom';
import { useReducerWithMiddleware } from '../shared/MyReducer';

import WordleIcon from '../resources/twordle.png';
import { TwordleIcon } from './TwordleIcon';
import { TwordleIconTwo } from './TwordleIconTwo';

export const LandingPage = () => {
  const nav = useNavigate();
  const [{ buttonStatus }, dispatch] = useReducerWithMiddleware(
    reducer,
    {
      buttonStatus: 'start',
      matched: false,
      pingInterval: PING_START,
    },
    [reducerMiddleware],
    [reducerAfterware]
  );

  // side effects
  const navigateToArena = useCallback(() => nav('/twordle/play'), []);
  const pingServer = useCallback(() => {
    sendJsonMessage({ action: PING });
  }, []);
  const sendWaitingRoomEnter = useCallback(() => {
    const message = {
      action: WAITING_ROOM_ENTER_ACTION,
      userId: getUserIdInLocalStorage(),
    };
    console.log(`${JSON.stringify(message)}`);
    sendJsonMessage(message);
  }, []);

  const sendWaitingRoomLeave = useCallback(
    () =>
      sendJsonMessage({
        action: WAITING_ROOM_LEAVE_ACTION,
      }),
    []
  );

  const setUserIdInLocalStorage = useCallback((userId: string) => {
    if (!userId)
      throw new Error(`trying to set an invalid userId: ${userId}`);
    localStorage.setItem('userId', userId);
  }, []);

  const setArenaIdInLocalStorage = (arenaId: string) => {
    if (!arenaId)
      throw new Error(`trying to set an arenaId: ${arenaId}`);
    localStorage.setItem('arenaId', arenaId);
  };

  const getUserIdInLocalStorage = useCallback((): string | null => {
    return localStorage.getItem('userId');
  }, []);

  const setUsernameSessionStorage = useCallback(
    (username: string) => {
      sessionStorage.setItem('username', username);
    },
    []
  );

  const handleUsernameChange = useCallback((event: any) => {
    dispatch({
      type: 'username_change',
      setUsernameSessionStorage,
      username: event?.target?.value,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'on_app_start',
      getUserIdInLocalStorage,
      setUserIdInLocalStorage,
    });
  }, []);

  useBeforeUnload(() => {
    dispatch({ type: 'connection_closed', sendWaitingRoomLeave });
  });

  const startButton = useCallback(
    () => dispatch({ type: 'waiting_started', sendWaitingRoomEnter }),
    []
  );

  const cancelButton = useCallback(
    () =>
      dispatch({ type: 'waiting_canceled', sendWaitingRoomLeave }),
    []
  );

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    socketAddress,
    {
      onError: (event: Event) => console.log(JSON.stringify(event)),
      onMessage: (event: MessageEvent) => {
        console.log(JSON.stringify(event));
      },
      onClose: () =>
        dispatch({ type: 'connection_closed', sendWaitingRoomLeave }),
      onOpen: () => console.log('open'),
      shouldReconnect: (closeEvent) => true,
      share: true,
    }
  );

  useInterval(() => {
    dispatch({ type: 'ping_tick', pingServer });
  }, 1000);

  useEffect(() => {
    const action = wsEventToAction(
      lastJsonMessage,
      navigateToArena,
      setArenaIdInLocalStorage
    );
    dispatch(action);
  }, [lastJsonMessage]);

  const waitingText = 'Waiting for an opponent...';
  return (
    <div className="container">
      <div className="login page">
        <TwordleIcon />
        <TwordleIconTwo />
        <h1 className="title">Twordle</h1>
        <p className="desc">Wordle for Two</p>
        <p className="descTwo">
          Guess their word before they guess yours
        </p>
        <p className="desc"></p>

        {buttonStatus === 'start' && (
          <button className="button" onClick={startButton}>
            Play
          </button>
        )}
        {buttonStatus === 'waiting' && (
          <div>
            <button className="button" onClick={cancelButton}>
              Cancel
            </button>
          </div>
        )}
        <p
          className={`waiting ${
            buttonStatus === 'waiting' ? 'exposed' : ''
          }`}
        >
          {waitingText}
        </p>
      </div>
    </div>
  );
};
