import './Arena.css';
import { Start } from './start/Start';

import useWebSocket from 'react-use-websocket';
import { socketAddress } from '../shared/MySocket';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Board } from './board/Board';
import { useReducerWithMiddleware } from '../shared/MyReducer';
import {
  getStartingTimer,
  reducer,
  reducerAfterware,
  reducerMiddleware,
} from './reducer';
import { ArenaEffects, eventToAction } from './eventToAction';
import { useInterval } from 'usehooks-ts';
import { PlayingState } from './State';
import { Timer } from './timer/Timer';
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import {
  ModalType,
  startScreenDetailsMap,
} from './start/StartScreenDetails';
export const PING = 'ping';
export const Arena = () => {
  const [
    {
      playingState,
      myBoard,
      oppBoard,
      myColor,
      oppColor,
      myWord,
      timerDetails,
      modalType,
    },
    dispatch,
  ] = useReducerWithMiddleware(
    reducer,
    {
      playingState: PlayingState.END,
      myBoard: '',
      oppBoard: '',
      myColor: '',
      oppColor: '',
      modalType: ModalType.START,
      userId: '',
      userName: '',
      endTime: 0,
      lastUpdated: 0,
      hasPlayed: false,
      timerDetails: {
        ...getStartingTimer(),
        isTicking: false,
      },
    },
    [reducerMiddleware],
    [reducerAfterware]
  );

  const nav = useNavigate();
  const navigateToLandingPage = useCallback(
    () => nav('/twordle'),
    [nav]
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalType) ref?.current?.focus();
  }, [modalType]);

  useEffect(() => {
    dispatch({
      type: 'init_arena',
      getUserId,
      getUsername,
      sendWithIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if userId is null, what happens?
  // usecallback?
  const getUserId = (): string => localStorage.getItem('userId')!;
  const getArenaId = (): string => localStorage.getItem('arenaId')!;
  if (!getArenaId() || !getUserId()) {
    throw Error('asdf');
  }
  const sendWithIds = (action: string, incomingMessage: any) => {
    const message = {
      action,
      ...incomingMessage,
      playerId: getUserId(),
      arenaId: getArenaId(),
    };
    console.log(message);
    sendJsonMessage(message);
  };

  // actually this is not necessary to be provided.
  // usecallback?
  const getUsername = (): string =>
    sessionStorage.getItem('username')!;

  const effects: ArenaEffects = useMemo(() => {
    return {
      sendToOpp: (myBoard: string, timestamp: number) => {
        sendWithIds('arena_playing_typing', {
          playerBoard: myBoard,
          timestamp,
        });
      },
      highlightRow: (rowInd: number) => {},
      pingServer: async () => {
        sendJsonMessage({ action: PING });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    dispatch({ type: 'ping_tick', pingServer: effects.pingServer });
  }, 15000);

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    socketAddress,
    {
      onError: (event: Event) => {
        console.log(JSON.stringify(event));
      },
      onMessage: (event: MessageEvent) => {},
      onClose: () => console.log('closed'),
      onOpen: (event) => console.log(event),
      shouldReconnect: (closeEvent) => true,
      share: true,
    }
  );

  useBeforeUnload(() => {
    dispatch({ type: 'connection_closed', sendWithIds });
  });

  useEffect(() => {
    if (lastJsonMessage === null) return;
    const action = eventToAction(lastJsonMessage, effects);
    dispatch(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  // can do something more complex here but right now let's just start if backend is connected.
  // const onStartClicked = () => dispatch({ type: 'start' });
  const onModalButtonClicked = () => {
    dispatch({
      type: 'on_modal_button_clicked',
      sendWithIds,
      navigateToLandingPage,
    });
  };
  const onKeyDown = (event: any) => {
    dispatch({
      type: 'key_pressed',
      key: event.key,
      sendToOpp: effects.sendToOpp,
      sendWithIds,
    });
  };

  return (
    <div
      ref={ref}
      onKeyDown={onKeyDown}
      tabIndex={0}
      className="ArenaContainer"
    >
      <Timer
        minutes={timerDetails.minutes}
        seconds={timerDetails.seconds}
        update={() => dispatch({ type: 'timer_tick' })}
        isTicking={timerDetails.isTicking}
      />

      <div className="BoardContainers">
        <div className="BoardContainer">
          {'John P Chen'}
          <Board
            text={myBoard
              .split('')
              .filter((c) => c !== 'E')
              .join('')}
            color={myColor}
          />
        </div>

        <div className="BoardContainer">
          {'Stephen Ch-ow'}
          <Board
            text={oppBoard
              .split('')
              .filter((c) => c !== 'E')
              .join('')}
            color={oppColor}
            opponentGuessWord={myWord}
          />
        </div>
      </div>
      {modalType !== undefined && (
        <Start
          modalScreen={startScreenDetailsMap.get(modalType!)!}
          onButtonClicked={onModalButtonClicked}
        />
      )}
    </div>
  );
};
