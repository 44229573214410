import { Action } from './reducer';

/**

Enter // happens once when we connect
'Ready'
'Error'

Ready // can happen multiple time either when we connect or rematch.
'Start'

Guess
'PlayingWin'
'PlayingLoss' - oppBoard, oppColor
'PlayingMyUpdate' - myColor
'PlayingOppUpdate' - myColor
'PlayingNotAWord'

Typing
PlayingTypingOppUpdate - oppBoard
 */
const EVENT_ERROR = 'Error';
const EVENT_READY = 'Ready';
const EVENT_START = 'Start';
const EVENT_WIN = 'PlayingWin';
const EVENT_LOSS = 'PlayingLoss';
const EVENT_WORD_APPROVED = 'PlayingMyUpdate';
const EVENT_WORD_DENIED = 'PlayingNotAWord';
const EVENT_OPP_COLOR_UPDATE = 'PlayingOppUpdate';
const EVENT_OPP_TYPING_UPDATE = 'PlayingTypingOppUpdate';
const EVENT_INTERNAL_SERVER_ERROR = 'Internal server error';

export type ArenaEffects = {
  sendToOpp: (myBoard: string, timestamp: number) => void;
  highlightRow: (ind: number) => void;
  pingServer: () => void;
};
export const eventToAction = (
  event: any,
  effects: ArenaEffects
): Action => {
  console.log(`received event!!: ${JSON.stringify(event)}`);

  const status = event?.twordle?.status;
  switch (status) {
    case EVENT_ERROR:
      const reason: string = event.twordle.reason!;
      console.log('event reason', event);
      return { type: 'error' };

    case EVENT_READY:
      return { type: 'ready' };

    case EVENT_START:
      const endTime: number = event.twordle.endTime!;
      const myWord: string = event.twordle.myWord!;
      return { type: 'start', endTime, myWord };

    case EVENT_WORD_APPROVED:
      const approvedColor: string = event.twordle.color!;
      return {
        type: 'word_approved',
        myColor: approvedColor,
      };

    case EVENT_WORD_DENIED:
      return { type: 'word_denied' };

    case EVENT_OPP_COLOR_UPDATE:
      return {
        type: 'opp_color_update',
        oppBoard: event.twordle.board!,
        oppColor: event.twordle.color!,
      };

    case EVENT_OPP_TYPING_UPDATE:
      return {
        type: 'opp_typing_update',
        oppBoard: event.twordle.oppBoard!,
        oppTimestamp: event.twordle.oppTimestamp!,
      };
    case EVENT_WIN:
      return {
        type: 'win',
        myBoard: event.twordle.myBoard,
        oppBoard: event.twordle.oppBoard,
        myColor: event.twordle.myColor,
        oppColor: event.twordle.oppColor,
      };
    case EVENT_LOSS:
      return {
        type: 'loss',
        myBoard: event.twordle.myBoard,
        oppBoard: event.twordle.oppBoard,
        myColor: event.twordle.myColor,
        oppColor: event.twordle.oppColor,
      };
    case EVENT_INTERNAL_SERVER_ERROR: {
      console.log(`internal server error event received: ${event}`);
      return {
        type: 'error',
      };
    }

    default:
      return { type: 'received_unknown_event', event };
  }
};
