import { useState } from 'react';

import './Timer.css';

export type TimerProps = {
  minutes: string;
  seconds: string;
  update: () => void;
  isTicking: boolean;
};
export const Timer = (props: TimerProps) => {
  const { minutes, seconds, update, isTicking } = props;
  const [timerInterval, setTimerInterval] =
    useState<NodeJS.Timer | null>(null);

  if (isTicking && !timerInterval) {
    const intervalId = setInterval(() => update(), 1000);
    setTimerInterval(intervalId);
  }

  if (!isTicking && timerInterval) {
    clearInterval(timerInterval);
    setTimerInterval(null);
  }

  return (
    <div className="Timer">
      {minutes} : {seconds}
    </div>
  );
};
