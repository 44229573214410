import { PlayingState, State } from './State';
import { ModalType } from './start/StartScreenDetails';
import { calculateTimeRemaining } from './timer/timerCalculator';

const enterKey = 'Enter';
const deleteKey = 'Backspace';
const myBoardEnter = 'E'; // use a capital letter because it's not allowed
const timerMaxMs = 120000;

export const getStartingTimer = () =>
  calculateTimeRemaining(Date.now() + timerMaxMs);
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'key_pressed': {
      const { myBoard, playingState } = state;
      if (playingState !== PlayingState.PLAYING) {
        return state;
      }
      const { key } = action;

      const isFullLine = myBoard.length % 6 === 5;
      const isEmptyLine = myBoard.length % 6 === 0;

      if (key === deleteKey) {
        if (!isEmptyLine) {
          return {
            ...state,
            myBoard: myBoard.substring(0, myBoard.length - 1),
          };
        }

        //otherwise do nothing.
        return { ...state };
      }

      const keyLc = key.toLowerCase();
      if (keyLc.length === 1 && keyLc.match(/[a-z]/)) {
        if (!isFullLine) {
          return { ...state, myBoard: state.myBoard + action.key };
        }
      }

      return {
        ...state,
        timerDetails: { ...state.timerDetails },
      };
    }

    case 'received_unknown_event': {
      console.log(
        `received_unknown_event, ${JSON.stringify(action)}`
      );
      return { ...state };
    }

    case 'word_approved': {
      return {
        ...state,
        myBoard: state.myBoard + myBoardEnter,
        myColor: action.myColor,
      };
    }

    case 'init_arena': {
      console.log(`in init_arena reducer`);
      const { getUserId, getUsername } = action;
      return {
        ...state,
        userId: getUserId(),
        userName: getUsername(),
      };
    }

    case 'opp_typing_update': {
      const { oppBoard, oppTimestamp } = action;
      // this guards against typing updates that are sent out of order
      // and overwriting opp board with stale data.
      console.log('stored incoming', state.lastUpdated, oppTimestamp);
      if (oppTimestamp < state.lastUpdated) {
        console.log('caughtone!!!!');
        return { ...state };
      }
      return { ...state, oppBoard, lastUpdated: oppTimestamp };
    }

    case 'opp_color_update': {
      const { oppColor, oppBoard } = action;

      return { ...state, oppBoard, oppColor };
    }

    case 'start': {
      const { endTime, myWord } = action;
      return {
        ...state,
        playingState: PlayingState.PLAYING,
        myWord,
        modalType: undefined,
        endTime,
        myBoard: '',
        myColor: '',
        oppBoard: '',
        oppColor: '',
        hasPlayed: true,
        timerDetails: {
          ...calculateTimeRemaining(endTime),
          isTicking: true,
        },
      };
    }
    case 'ready': {
      return {
        ...state,
        playingState: PlayingState.END,
        modalType: ModalType.START,
      };
    }
    case 'win': {
      const { myBoard, myColor, oppBoard, oppColor } = action;
      return {
        ...state,
        playingState: PlayingState.END,
        myColor: myColor ? myColor : state.myColor,
        myBoard: myBoard ? myBoard : state.myBoard,
        oppBoard: oppBoard ? oppBoard : state.oppBoard,
        oppColor: oppColor ? oppColor : state.oppColor,
        modalType: ModalType.WIN_REMATCH,
      };
    }

    case 'draw': {
      return {
        ...state,
        playingState: PlayingState.END,
        modalType: ModalType.DRAW_REMATCH,
      };
    }

    case 'loss': {
      const { myBoard, myColor, oppBoard, oppColor } = action;
      return {
        ...state,
        playingState: PlayingState.END,
        myColor: myColor ? myColor : state.myColor,
        myBoard: myBoard ? myBoard : state.myBoard,
        oppBoard: oppBoard ? oppBoard : state.oppBoard,
        oppColor: oppColor ? oppColor : state.oppColor,
        modalType: ModalType.LOSE_REMATCH,
      };
    }

    case 'timer_tick': {
      // bugfix, when win/lose, isTicking is set false but one more tick reruns this logic.
      // pretty bad impl imo.
      if (!state.timerDetails.isTicking) {
        return { ...state };
      }
      // win or loss or time has ran out, stop ticking
      if (
        state.modalType === ModalType.LOSE_REMATCH ||
        state.modalType === ModalType.WIN_REMATCH ||
        Date.now() > state.endTime
      ) {
        const minAndSecEnd = calculateTimeRemaining(
          Date.now() + timerMaxMs
        );
        return {
          ...state,
          timerDetails: {
            ...minAndSecEnd,
            isTicking: false,
          },
          playingState: PlayingState.END,
          modalType:
            state.playingState === PlayingState.PLAYING
              ? ModalType.DRAW_REMATCH
              : state.modalType, // changed this justnow
        };
      }
      const minAndSec = calculateTimeRemaining(state.endTime);

      return {
        ...state,
        timerDetails: { ...minAndSec, isTicking: true },
      };
    }

    case 'word_denied': {
      console.log('word_denied called!!');

      return {
        ...state,
      };
    }

    case 'on_modal_button_clicked': {
      if (
        state.modalType === ModalType.WIN_REMATCH ||
        state.modalType === ModalType.LOSE_REMATCH ||
        state.modalType === ModalType.DRAW_REMATCH ||
        state.modalType === ModalType.START
      ) {
        return {
          ...state,
          modalType: ModalType.WAITING,
        };
      }

      if (
        state.modalType === ModalType.REMATCH_DECLINED ||
        state.modalType === ModalType.ERROR
      ) {
        // middleware handles navigation
        return {
          ...state,
        };
      }

      if (state.modalType === ModalType.WAITING) {
        // if waiting and cancel was clicked
        return {
          ...state,
          modalType: ModalType.START,
        };
      }
      return { ...state };
    }

    case 'error': {
      return {
        ...state,
        modalType: ModalType.ERROR,
        playingState: PlayingState.END,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const reducerMiddleware = (state: State, action: Action) => {
  switch (action.type) {
    case 'key_pressed': {
      const { myBoard, playingState } = state;
      if (playingState !== PlayingState.PLAYING) {
        return;
      }
      // const { key, shakeRow, checkWord } = action;
      const { key, sendWithIds } = action;
      const isFullLine = myBoard.length % 6 === 5;

      if (key === enterKey) {
        if (isFullLine) {
          // send whole board to backend
          sendWithIds('arena_playing_guess', {
            playerBoard: myBoard,
          });
        } else {
          // const rowInd = Math.floor(myBoard.length / 6);
          // shakeRow(rowInd);
        }
      }
      break;
    }
    case 'ping_tick': {
      action.pingServer();
      break;
    }
    case 'init_arena': {
      break;
    }

    case 'on_modal_button_clicked': {
      const { sendWithIds, navigateToLandingPage } = action;

      // exit -- rematch declined or error
      if (
        state.modalType === ModalType.REMATCH_DECLINED ||
        state.modalType === ModalType.ERROR
      ) {
        navigateToLandingPage();
        break;
      }

      // cancel -- waiting
      if (state.modalType === ModalType.WAITING) {
        // todo cancel here
        break;
      }

      // accept ready -- start, rematch
      sendWithIds('arena_ready', { playLengthMs: timerMaxMs });

      break;
    }
    case 'connection_closed': {
      action.sendWithIds('arena_leave', undefined);
      break;
    }
  }
};

export const reducerAfterware = (state: State, action: Action) => {
  switch (action.type) {
    case 'init_arena': {
      const { sendWithIds } = action;
      sendWithIds('arena_enter', undefined);
      break;
    }
    case 'word_approved': {
      // const { myBoard } = state;
      // const { highlightRow } = action;
      // const row = Math.floor(myBoard.length / 6) - 1;
      // highlightRow(row);
      break;
    }

    case 'key_pressed': {
      const { myBoard, playingState } = state;
      if (playingState !== PlayingState.PLAYING) {
        return;
      }
      const { key, sendToOpp } = action;

      // enter key is handled in middleware
      if (key === enterKey) {
        break;
      }
      // reducer will change the state, we send updates after
      sendToOpp(myBoard, new Date().getTime());
      // enter
      break;
    }
  }
};

export type Action =
  | { type: 'ping_tick'; pingServer: () => void }
  | { type: 'timer_tick' }
  | {
      type: 'key_pressed';
      key: string;
      // shakeRow: (a: number) => void;
      sendWithIds: (action: string, message: any) => void;
      sendToOpp: (a: string, timestamp: number) => void;
    }
  | {
      type: 'init_arena';
      getUserId: () => string;
      getUsername: () => string;
      sendWithIds: (action: string, message: any) => void;
    }
  | { type: 'error' }
  | { type: 'ready' }
  | { type: 'start'; endTime: number; myWord: string }
  | {
      type: 'on_modal_button_clicked';
      sendWithIds: (action: string, message: any) => void;
      navigateToLandingPage: () => void;
    }
  | {
      type: 'win';
      myBoard?: string;
      myColor?: string;
      oppBoard?: string;
      oppColor?: string;
    }
  | {
      type: 'loss';
      myBoard?: string;
      myColor?: string;
      oppBoard?: string;
      oppColor?: string;
    }
  | { type: 'draw'; oppBoard: string }
  | {
      type: 'word_approved';
      myColor: string;
    }
  | { type: 'word_denied' }
  | { type: 'opp_color_update'; oppBoard: string; oppColor: string }
  | {
      type: 'opp_typing_update';
      oppBoard: string;
      oppTimestamp: number;
    }
  | {
      type: 'connection_closed';
      sendWithIds: (action: string, message: any) => void;
    }
  | { type: 'received_unknown_event'; event: any };
